import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91organizationId_93H2vn0Kta0tMeta } from "/app/pages/admin/organizations/[organizationId].vue?macro=true";
import { default as indexPItleGRp5mMeta } from "/app/pages/admin/organizations/index.vue?macro=true";
import { default as _91userId_93vm7ITBo7uPMeta } from "/app/pages/admin/users/[userId].vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as artifactshuesF2vXbtMeta } from "/app/pages/artifacts.vue?macro=true";
import { default as indexUgoFhRBMnvMeta } from "/app/pages/cgbank/artifacts/index.vue?macro=true";
import { default as indexIYpPnorcecMeta } from "/app/pages/cgbank/consent/index.vue?macro=true";
import { default as demo_45artifact_45dialogJeVmzZUlftMeta } from "/app/pages/cgbank/demo/demo-artifact-dialog.vue?macro=true";
import { default as demo_45artifacts8d3OcjOfLhMeta } from "/app/pages/cgbank/demo/demo-artifacts.ts?macro=true";
import { default as indexgWAtgEfYFdMeta } from "/app/pages/cgbank/demo/index.vue?macro=true";
import { default as indexzjyxO2Jt0gMeta } from "/app/pages/cgbank/favorites/index.vue?macro=true";
import { default as collectionStq1KmcevkMeta } from "/app/pages/cgbank/index/collection.vue?macro=true";
import { default as hero_45cardHxAzMYXSerMeta } from "/app/pages/cgbank/index/hero-card.vue?macro=true";
import { default as hero_45linkowZ4Gmp2zgMeta } from "/app/pages/cgbank/index/hero-link.vue?macro=true";
import { default as hero_45servicehucrOHUuaRMeta } from "/app/pages/cgbank/index/hero-service.vue?macro=true";
import { default as heroKa47GHVeVNMeta } from "/app/pages/cgbank/index/hero.vue?macro=true";
import { default as indexBna2p4FRsCMeta } from "/app/pages/cgbank/index/index.vue?macro=true";
import { default as indexbrJUQK5vsgMeta } from "/app/pages/cgbank/pricing/index.vue?macro=true";
import { default as privacylxwrsXig9kMeta } from "/app/pages/cgbank/privacy.vue?macro=true";
import { default as indexj6KOjN0eY7Meta } from "/app/pages/cgbank/profile/index.vue?macro=true";
import { default as sctl1rc4kGfDkVMeta } from "/app/pages/cgbank/sctl.vue?macro=true";
import { default as cancelI0wv9p30G9Meta } from "/app/pages/cgbank/subscriptions/cancel.vue?macro=true";
import { default as termkMve0MP779Meta } from "/app/pages/cgbank/term.vue?macro=true";
import { default as indexz9XY3AJyPiMeta } from "/app/pages/cgbank/verify/index.vue?macro=true";
import { default as consentGvS2D7CHlRMeta } from "/app/pages/consent.vue?macro=true";
import { default as generatechsxkLJ4zPMeta } from "/app/pages/generate.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as plan1VKmH9fE8fMeta } from "/app/pages/plan.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as sctl3jUrz3azvbMeta } from "/app/pages/sctl.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as cancel1CoPVSKpueMeta } from "/app/pages/subscriptions/cancel.vue?macro=true";
import { default as successrVysLx7SdsMeta } from "/app/pages/subscriptions/success.vue?macro=true";
import { default as termJlyDIwxnBpMeta } from "/app/pages/term.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
import { default as usageszltKNNFBFkMeta } from "/app/pages/usages.vue?macro=true";
import { default as verifyuQf3dnBI8DMeta } from "/app/pages/verify.vue?macro=true";
export default [
  {
    name: indexi8nCATZISJMeta?.name ?? "admin",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91organizationId_93H2vn0Kta0tMeta?.name ?? "admin-organizations-organizationId",
    path: _91organizationId_93H2vn0Kta0tMeta?.path ?? "/admin/organizations/:organizationId()",
    meta: _91organizationId_93H2vn0Kta0tMeta || {},
    alias: _91organizationId_93H2vn0Kta0tMeta?.alias || [],
    redirect: _91organizationId_93H2vn0Kta0tMeta?.redirect,
    component: () => import("/app/pages/admin/organizations/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: indexPItleGRp5mMeta?.name ?? "admin-organizations",
    path: indexPItleGRp5mMeta?.path ?? "/admin/organizations",
    meta: indexPItleGRp5mMeta || {},
    alias: indexPItleGRp5mMeta?.alias || [],
    redirect: indexPItleGRp5mMeta?.redirect,
    component: () => import("/app/pages/admin/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93vm7ITBo7uPMeta?.name ?? "admin-users-userId",
    path: _91userId_93vm7ITBo7uPMeta?.path ?? "/admin/users/:userId()",
    meta: _91userId_93vm7ITBo7uPMeta || {},
    alias: _91userId_93vm7ITBo7uPMeta?.alias || [],
    redirect: _91userId_93vm7ITBo7uPMeta?.redirect,
    component: () => import("/app/pages/admin/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexYm9dzWmM2AMeta?.name ?? "admin-users",
    path: indexYm9dzWmM2AMeta?.path ?? "/admin/users",
    meta: indexYm9dzWmM2AMeta || {},
    alias: indexYm9dzWmM2AMeta?.alias || [],
    redirect: indexYm9dzWmM2AMeta?.redirect,
    component: () => import("/app/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: artifactshuesF2vXbtMeta?.name ?? "artifacts",
    path: artifactshuesF2vXbtMeta?.path ?? "/artifacts",
    meta: artifactshuesF2vXbtMeta || {},
    alias: artifactshuesF2vXbtMeta?.alias || [],
    redirect: artifactshuesF2vXbtMeta?.redirect,
    component: () => import("/app/pages/artifacts.vue").then(m => m.default || m)
  },
  {
    name: indexUgoFhRBMnvMeta?.name ?? "cgbank-artifacts",
    path: indexUgoFhRBMnvMeta?.path ?? "/cgbank/artifacts",
    meta: indexUgoFhRBMnvMeta || {},
    alias: indexUgoFhRBMnvMeta?.alias || [],
    redirect: indexUgoFhRBMnvMeta?.redirect,
    component: () => import("/app/pages/cgbank/artifacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexIYpPnorcecMeta?.name ?? "cgbank-consent",
    path: indexIYpPnorcecMeta?.path ?? "/cgbank/consent",
    meta: indexIYpPnorcecMeta || {},
    alias: indexIYpPnorcecMeta?.alias || [],
    redirect: indexIYpPnorcecMeta?.redirect,
    component: () => import("/app/pages/cgbank/consent/index.vue").then(m => m.default || m)
  },
  {
    name: demo_45artifact_45dialogJeVmzZUlftMeta?.name ?? "cgbank-demo-demo-artifact-dialog",
    path: demo_45artifact_45dialogJeVmzZUlftMeta?.path ?? "/cgbank/demo/demo-artifact-dialog",
    meta: demo_45artifact_45dialogJeVmzZUlftMeta || {},
    alias: demo_45artifact_45dialogJeVmzZUlftMeta?.alias || [],
    redirect: demo_45artifact_45dialogJeVmzZUlftMeta?.redirect,
    component: () => import("/app/pages/cgbank/demo/demo-artifact-dialog.vue").then(m => m.default || m)
  },
  {
    name: demo_45artifacts8d3OcjOfLhMeta?.name ?? "cgbank-demo-demo-artifacts",
    path: demo_45artifacts8d3OcjOfLhMeta?.path ?? "/cgbank/demo/demo-artifacts",
    meta: demo_45artifacts8d3OcjOfLhMeta || {},
    alias: demo_45artifacts8d3OcjOfLhMeta?.alias || [],
    redirect: demo_45artifacts8d3OcjOfLhMeta?.redirect,
    component: () => import("/app/pages/cgbank/demo/demo-artifacts.ts").then(m => m.default || m)
  },
  {
    name: indexgWAtgEfYFdMeta?.name ?? "cgbank-demo",
    path: indexgWAtgEfYFdMeta?.path ?? "/cgbank/demo",
    meta: indexgWAtgEfYFdMeta || {},
    alias: indexgWAtgEfYFdMeta?.alias || [],
    redirect: indexgWAtgEfYFdMeta?.redirect,
    component: () => import("/app/pages/cgbank/demo/index.vue").then(m => m.default || m)
  },
  {
    name: indexzjyxO2Jt0gMeta?.name ?? "cgbank-favorites",
    path: indexzjyxO2Jt0gMeta?.path ?? "/cgbank/favorites",
    meta: indexzjyxO2Jt0gMeta || {},
    alias: indexzjyxO2Jt0gMeta?.alias || [],
    redirect: indexzjyxO2Jt0gMeta?.redirect,
    component: () => import("/app/pages/cgbank/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: collectionStq1KmcevkMeta?.name ?? "cgbank-index-collection",
    path: collectionStq1KmcevkMeta?.path ?? "/cgbank/collection",
    meta: collectionStq1KmcevkMeta || {},
    alias: collectionStq1KmcevkMeta?.alias || [],
    redirect: collectionStq1KmcevkMeta?.redirect,
    component: () => import("/app/pages/cgbank/index/collection.vue").then(m => m.default || m)
  },
  {
    name: hero_45cardHxAzMYXSerMeta?.name ?? "cgbank-index-hero-card",
    path: hero_45cardHxAzMYXSerMeta?.path ?? "/cgbank/hero-card",
    meta: hero_45cardHxAzMYXSerMeta || {},
    alias: hero_45cardHxAzMYXSerMeta?.alias || [],
    redirect: hero_45cardHxAzMYXSerMeta?.redirect,
    component: () => import("/app/pages/cgbank/index/hero-card.vue").then(m => m.default || m)
  },
  {
    name: hero_45linkowZ4Gmp2zgMeta?.name ?? "cgbank-index-hero-link",
    path: hero_45linkowZ4Gmp2zgMeta?.path ?? "/cgbank/hero-link",
    meta: hero_45linkowZ4Gmp2zgMeta || {},
    alias: hero_45linkowZ4Gmp2zgMeta?.alias || [],
    redirect: hero_45linkowZ4Gmp2zgMeta?.redirect,
    component: () => import("/app/pages/cgbank/index/hero-link.vue").then(m => m.default || m)
  },
  {
    name: hero_45servicehucrOHUuaRMeta?.name ?? "cgbank-index-hero-service",
    path: hero_45servicehucrOHUuaRMeta?.path ?? "/cgbank/hero-service",
    meta: hero_45servicehucrOHUuaRMeta || {},
    alias: hero_45servicehucrOHUuaRMeta?.alias || [],
    redirect: hero_45servicehucrOHUuaRMeta?.redirect,
    component: () => import("/app/pages/cgbank/index/hero-service.vue").then(m => m.default || m)
  },
  {
    name: heroKa47GHVeVNMeta?.name ?? "cgbank-index-hero",
    path: heroKa47GHVeVNMeta?.path ?? "/cgbank/hero",
    meta: heroKa47GHVeVNMeta || {},
    alias: heroKa47GHVeVNMeta?.alias || [],
    redirect: heroKa47GHVeVNMeta?.redirect,
    component: () => import("/app/pages/cgbank/index/hero.vue").then(m => m.default || m)
  },
  {
    name: indexBna2p4FRsCMeta?.name ?? "cgbank-index",
    path: indexBna2p4FRsCMeta?.path ?? "/cgbank",
    meta: indexBna2p4FRsCMeta || {},
    alias: indexBna2p4FRsCMeta?.alias || [],
    redirect: indexBna2p4FRsCMeta?.redirect,
    component: () => import("/app/pages/cgbank/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrJUQK5vsgMeta?.name ?? "cgbank-pricing",
    path: indexbrJUQK5vsgMeta?.path ?? "/cgbank/pricing",
    meta: indexbrJUQK5vsgMeta || {},
    alias: indexbrJUQK5vsgMeta?.alias || [],
    redirect: indexbrJUQK5vsgMeta?.redirect,
    component: () => import("/app/pages/cgbank/pricing/index.vue").then(m => m.default || m)
  },
  {
    name: privacylxwrsXig9kMeta?.name ?? "cgbank-privacy",
    path: privacylxwrsXig9kMeta?.path ?? "/cgbank/privacy",
    meta: privacylxwrsXig9kMeta || {},
    alias: privacylxwrsXig9kMeta?.alias || [],
    redirect: privacylxwrsXig9kMeta?.redirect,
    component: () => import("/app/pages/cgbank/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexj6KOjN0eY7Meta?.name ?? "cgbank-profile",
    path: indexj6KOjN0eY7Meta?.path ?? "/cgbank/profile",
    meta: indexj6KOjN0eY7Meta || {},
    alias: indexj6KOjN0eY7Meta?.alias || [],
    redirect: indexj6KOjN0eY7Meta?.redirect,
    component: () => import("/app/pages/cgbank/profile/index.vue").then(m => m.default || m)
  },
  {
    name: sctl1rc4kGfDkVMeta?.name ?? "cgbank-sctl",
    path: sctl1rc4kGfDkVMeta?.path ?? "/cgbank/sctl",
    meta: sctl1rc4kGfDkVMeta || {},
    alias: sctl1rc4kGfDkVMeta?.alias || [],
    redirect: sctl1rc4kGfDkVMeta?.redirect,
    component: () => import("/app/pages/cgbank/sctl.vue").then(m => m.default || m)
  },
  {
    name: cancelI0wv9p30G9Meta?.name ?? "cgbank-subscriptions-cancel",
    path: cancelI0wv9p30G9Meta?.path ?? "/cgbank/subscriptions/cancel",
    meta: cancelI0wv9p30G9Meta || {},
    alias: cancelI0wv9p30G9Meta?.alias || [],
    redirect: cancelI0wv9p30G9Meta?.redirect,
    component: () => import("/app/pages/cgbank/subscriptions/cancel.vue").then(m => m.default || m)
  },
  {
    name: termkMve0MP779Meta?.name ?? "cgbank-term",
    path: termkMve0MP779Meta?.path ?? "/cgbank/term",
    meta: termkMve0MP779Meta || {},
    alias: termkMve0MP779Meta?.alias || [],
    redirect: termkMve0MP779Meta?.redirect,
    component: () => import("/app/pages/cgbank/term.vue").then(m => m.default || m)
  },
  {
    name: indexz9XY3AJyPiMeta?.name ?? "cgbank-verify",
    path: indexz9XY3AJyPiMeta?.path ?? "/cgbank/verify",
    meta: indexz9XY3AJyPiMeta || {},
    alias: indexz9XY3AJyPiMeta?.alias || [],
    redirect: indexz9XY3AJyPiMeta?.redirect,
    component: () => import("/app/pages/cgbank/verify/index.vue").then(m => m.default || m)
  },
  {
    name: consentGvS2D7CHlRMeta?.name ?? "consent",
    path: consentGvS2D7CHlRMeta?.path ?? "/consent",
    meta: consentGvS2D7CHlRMeta || {},
    alias: consentGvS2D7CHlRMeta?.alias || [],
    redirect: consentGvS2D7CHlRMeta?.redirect,
    component: () => import("/app/pages/consent.vue").then(m => m.default || m)
  },
  {
    name: generatechsxkLJ4zPMeta?.name ?? "generate",
    path: generatechsxkLJ4zPMeta?.path ?? "/generate",
    meta: generatechsxkLJ4zPMeta || {},
    alias: generatechsxkLJ4zPMeta?.alias || [],
    redirect: generatechsxkLJ4zPMeta?.redirect,
    component: () => import("/app/pages/generate.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: plan1VKmH9fE8fMeta?.name ?? "plan",
    path: plan1VKmH9fE8fMeta?.path ?? "/plan",
    meta: plan1VKmH9fE8fMeta || {},
    alias: plan1VKmH9fE8fMeta?.alias || [],
    redirect: plan1VKmH9fE8fMeta?.redirect,
    component: () => import("/app/pages/plan.vue").then(m => m.default || m)
  },
  {
    name: privacyg3gwBRGXatMeta?.name ?? "privacy",
    path: privacyg3gwBRGXatMeta?.path ?? "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    alias: privacyg3gwBRGXatMeta?.alias || [],
    redirect: privacyg3gwBRGXatMeta?.redirect,
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: sctl3jUrz3azvbMeta?.name ?? "sctl",
    path: sctl3jUrz3azvbMeta?.path ?? "/sctl",
    meta: sctl3jUrz3azvbMeta || {},
    alias: sctl3jUrz3azvbMeta?.alias || [],
    redirect: sctl3jUrz3azvbMeta?.redirect,
    component: () => import("/app/pages/sctl.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? "settings",
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: cancel1CoPVSKpueMeta?.name ?? "subscriptions-cancel",
    path: cancel1CoPVSKpueMeta?.path ?? "/subscriptions/cancel",
    meta: cancel1CoPVSKpueMeta || {},
    alias: cancel1CoPVSKpueMeta?.alias || [],
    redirect: cancel1CoPVSKpueMeta?.redirect,
    component: () => import("/app/pages/subscriptions/cancel.vue").then(m => m.default || m)
  },
  {
    name: successrVysLx7SdsMeta?.name ?? "subscriptions-success",
    path: successrVysLx7SdsMeta?.path ?? "/subscriptions/success",
    meta: successrVysLx7SdsMeta || {},
    alias: successrVysLx7SdsMeta?.alias || [],
    redirect: successrVysLx7SdsMeta?.redirect,
    component: () => import("/app/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: termJlyDIwxnBpMeta?.name ?? "term",
    path: termJlyDIwxnBpMeta?.path ?? "/term",
    meta: termJlyDIwxnBpMeta || {},
    alias: termJlyDIwxnBpMeta?.alias || [],
    redirect: termJlyDIwxnBpMeta?.redirect,
    component: () => import("/app/pages/term.vue").then(m => m.default || m)
  },
  {
    name: testL3AVDvhyXeMeta?.name ?? "test",
    path: testL3AVDvhyXeMeta?.path ?? "/test",
    meta: testL3AVDvhyXeMeta || {},
    alias: testL3AVDvhyXeMeta?.alias || [],
    redirect: testL3AVDvhyXeMeta?.redirect,
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: usageszltKNNFBFkMeta?.name ?? "usages",
    path: usageszltKNNFBFkMeta?.path ?? "/usages",
    meta: usageszltKNNFBFkMeta || {},
    alias: usageszltKNNFBFkMeta?.alias || [],
    redirect: usageszltKNNFBFkMeta?.redirect,
    component: () => import("/app/pages/usages.vue").then(m => m.default || m)
  },
  {
    name: verifyuQf3dnBI8DMeta?.name ?? "verify",
    path: verifyuQf3dnBI8DMeta?.path ?? "/verify",
    meta: verifyuQf3dnBI8DMeta || {},
    alias: verifyuQf3dnBI8DMeta?.alias || [],
    redirect: verifyuQf3dnBI8DMeta?.redirect,
    component: () => import("/app/pages/verify.vue").then(m => m.default || m)
  }
]